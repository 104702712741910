const SITE_API_BASE_URL: string = 'https://site.metropolis.io/api';

const GOOGLE_OAUTH_CLIENT_ID: string =
  '482980396840-pp6khfu6oab5tf5im72p8n5pmdhdt503.apps.googleusercontent.com';

const SQUARE_CALLBACK_URL: string = 'https://site.metropolis.io/payment/callback/square';

const STRIPE_PUBLISHABLE_KEY: string =
  'pk_live_51HulvBCXl2cS0UTV6h4F3wiiJXxjv6jch3jo7fb3Hi8RlPcBvrEhPdVdyiLHVkCHYE7AMldgyGY98HhqbxQ10mZj001lKwwAPs';

const productionConfig = {
  SITE_API_BASE_URL,
  GOOGLE_OAUTH_CLIENT_ID,
  SQUARE_CALLBACK_URL,
  STRIPE_PUBLISHABLE_KEY,
};

export default productionConfig;
