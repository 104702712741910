import React from 'react';

import { Box, StyledGoogleSignInButton, SpinningLoadingIcon } from './GoogleAuthButton.styled';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export default function GoogleAuthButton({ loading = false, ...passProps }: Props) {
  return (
    <Box>
      {loading && <SpinningLoadingIcon />}
      <StyledGoogleSignInButton type="button" disabled={loading} {...passProps} />
    </Box>
  );
}
