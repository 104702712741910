import { Effect, StoreWritable } from 'effector';

export type AsyncStatusMessage = string | null;
export type AsyncStatus = boolean | null;

export type AsyncActionStatuses = {
  [key: string]: AsyncStatus;
};

export type AsyncActionStatusMessages = {
  [key: string]: AsyncStatusMessage;
};

export type AsyncStatusState = {
  status: AsyncActionStatuses;
  statusMessage: AsyncActionStatusMessages;
};

export type SuccessPayload = {
  success: boolean;
  data: any; // TODO: Can we make this more specific?
  error: string | null | undefined;
  errors: { key: string; reason: string }[] | null | undefined;
};

export type SuccessResponse<Params = any> = {
  params: Params;
  result: SuccessPayload;
};

export interface FailPayload extends Error {
  message: string;
}

export type FailResponse<Params = any> = {
  params: Params;
  error: FailPayload;
};

export function handlePending({
  actionName,
}: {
  actionName: string;
}): (state: AsyncStatusState, isPending: boolean) => AsyncStatusState {
  return function pendingCallback(state, isPending) {
    return {
      ...state,
      status: {
        ...state.status,
        [`${actionName}Pending`]: isPending,
        [`${actionName}Success`]: isPending ? null : state.status[`${actionName}Success`],
      },
      statusMessage: {
        ...state.statusMessage,
        [`${actionName}Message`]: isPending ? null : state.statusMessage[`${actionName}Message`],
      },
    };
  };
}

function handleDone<Params>({
  actionName,
}: {
  actionName: string;
}): (state: AsyncStatusState, doneResults: SuccessResponse<Params>) => AsyncStatusState {
  return function doneCallback(state, { result: { success, error } }) {
    return {
      ...state,
      status: {
        ...state.status,
        [`${actionName}Pending`]: false,
        [`${actionName}Success`]: success,
      },
      statusMessage: {
        ...state.statusMessage,
        [`${actionName}Message`]: !success && error ? error : null,
      },
    };
  };
}

function handleFail<Params>({
  actionName,
}: {
  actionName: string;
}): (state: AsyncStatusState, failResults: FailResponse<Params>) => AsyncStatusState {
  return function failCallback(state, { error: { message } }) {
    return {
      ...state,
      status: {
        ...state.status,
        [`${actionName}Pending`]: false,
        [`${actionName}Success`]: false,
      },
      statusMessage: {
        ...state.statusMessage,
        [`${actionName}Message`]: message || null,
      },
    };
  };
}

export const addAsyncActionHandlers = <Params>(
  store: StoreWritable<AsyncStatusState>,
  action: Effect<Params, SuccessPayload, FailPayload>,
  actionName: string,
) => {
  store.on(action.pending, handlePending({ actionName }));
  store.on(action.done, handleDone<Params>({ actionName }));
  store.on(action.fail, handleFail<Params>({ actionName }));
};
