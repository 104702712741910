import { dataDogLogOut, dataDogSetUser } from './datadogService';
import { sentryLogout, sentrySetUser } from './sentryService';

import { THIRD_PARTY_TRACKING_ENDABLED } from 'constants/FeatureFlags';

import { User } from 'types/api';

export const userAnalytics = {
  setUser: (user: User) => {
    if (THIRD_PARTY_TRACKING_ENDABLED) {
      sentrySetUser(user);
      dataDogSetUser(user);
    }
  },
  unsetUser: () => {
    sentryLogout();
    dataDogLogOut();
  },
};
