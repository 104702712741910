import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import theme from '@m/theme/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export type AlertContainerProps = StyledSystemProps & {
  visible?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  color?: string;
  infoBgColor?: string;
  infoFgColor?: string;
  successBgColor?: string;
  successFgColor?: string;
  errorBgColor?: string;
  errorFgColor?: string;
  warningBgColor?: string;
  warningFgColor?: string;
  onClick?: () => void;
};

function getBackgroundColor({
  success,
  warning,
  error,
  successBgColor,
  warningBgColor,
  errorBgColor,
  infoBgColor,
}: AlertContainerProps) {
  if (success) {
    return successBgColor;
  }
  if (warning) {
    return warningBgColor;
  }
  if (error) {
    return errorBgColor;
  }
  return infoBgColor;
}

function getColor({
  success,
  warning,
  error,
  successFgColor,
  warningFgColor,
  errorFgColor,
  infoFgColor,
}: AlertContainerProps) {
  if (success) {
    return successFgColor;
  }
  if (warning) {
    return warningFgColor;
  }
  if (error) {
    return errorFgColor;
  }
  return infoFgColor;
}
export const AlertContainer = styled.button<AlertContainerProps>`
  z-index: 99999;
  min-height: 45px;
  position: fixed;
  left: 26px;
  top: 9px;
  right: 10px;
  background-color: ${getBackgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 9px 5px 10px;
  border-radius: 4px;
  font-size: 15px;
  letter-spacing: 0.015em;
  width: calc(100% - 52px);
  line-height: 1;
  font-weight: 700;
  color: ${getColor};
  text-align: center;
  user-select: none;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: translate3d(0, ${({ visible }) => (visible ? 0 : -62)}px, 0);
  transition:
    transform 300ms ease 0ms,
    opacity 150ms linear 0ms;
  transition-duration: ${({ visible }) => (visible ? '400ms,250ms' : '300ms,150ms')};
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.25),
    0 2px 7px rgba(0, 0, 0, 0.1),
    0 6px 16px rgba(0, 0, 0, 0.1);

  @media (min-width: 700px) {
    left: calc(50% - 140px);
    width: 280px;
  }
  ${withStyledSystem}
`;
AlertContainer.defaultProps = {
  type: 'button',
  infoBgColor: theme.colors.blue2,
  infoFgColor: theme.colors.blue7,
  successBgColor: theme.colors.green1,
  successFgColor: theme.colors.green4,
  errorBgColor: theme.colors.coral,
  errorFgColor: theme.colors.orange1,
  warningBgColor: theme.colors.yellow1,
  warningFgColor: theme.colors.grey6,
};
