import type { HTMLAttributes } from 'react';

import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export type TextProps = HTMLAttributes<HTMLSpanElement> & StyledSystemProps;

export const Text = styled.span<StyledSystemProps>`
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
  ${withStyledSystem}
`;

export const TextBold = styled.span<StyledSystemProps>`
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  ${withStyledSystem}
`;
