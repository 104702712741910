import FontNeueMontreal from '@m/assets/fonts/neue-montreal/FontNeueMontreal';
import { BrowserRouter } from 'react-router-dom';
import { fontDefault } from 'styled/fonts';
import { createGlobalStyle } from 'styled-components';

import SharedProviders from './shared';

export const GlobalStyle = createGlobalStyle`
  body {
    /* Ant Design sets the font family on the body, so we override it here */
    font-family: ${fontDefault};
  }
`;

export default function Providers({ children }: { children?: React.ReactNode }) {
  return (
    <SharedProviders>
      <FontNeueMontreal />
      <GlobalStyle />
      <BrowserRouter>{children}</BrowserRouter>
    </SharedProviders>
  );
}
