import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { setDefaultAuthMethod } from '@m/utils/http';

import Providers from 'providers';

import Intake from './Intake';
import { AntdOverrides, GlobalStyles } from './IntakeApp.styled';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function SpecialistApp() {
  return (
    <Providers>
      <Intake />
      <AntdOverrides />
      <GlobalStyles />
    </Providers>
  );
}
