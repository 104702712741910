import { INTAKE_VEHICLES_ID } from '@m/constants/UserPermissions';

import type { IdNameObject } from '@m/types';

export function hasIntakePermissions(permissions: IdNameObject[] = []) {
  if (permissions.find(({ id }) => id === INTAKE_VEHICLES_ID)) {
    return true;
  }
  return false;
}
