const DATA_REFRESH_INTERVAL: number = 5000;

const GOOGLE_OAUTH_CLIENT_ID: string =
  '882771224751-q5f3d01cg4jcrbbdng6qtsqbqkm6hamb.apps.googleusercontent.com';

const SQUARE_CLIENT_ID: string = 'sq0idp-XBafNc36avncpCyccLVOPA';

const HELP_URL: string = 'https://support.metropolis.io';

const STRIPE_PUBLISHABLE_KEY: string =
  'pk_test_51HulvBCXl2cS0UTVQGzGc9dt9NgRKznVArev0M07ndcZ59NS1c1rSilpHT6O9BNTyiS7pKcxTsJUH13SnVtCAOzV00meBCOPrs';

const sharedConfig = {
  DATA_REFRESH_INTERVAL,
  GOOGLE_OAUTH_CLIENT_ID,
  HELP_URL,
  SQUARE_CLIENT_ID,
  STRIPE_PUBLISHABLE_KEY,
};

export default sharedConfig;
