declare global {
  interface Window {
    heap: any;
  }
}

/**
 * https://developers.heap.io/docs/using-identify#identified-user-to-different-identity
 * @param userId
 * @param userUuid
 * @param email
 */
export const heapIdentifyUser = ({
  userId,
  userUuid,
  email,
}: {
  userId?: number;
  userUuid?: string;
  email?: string | number | null;
}) => {
  if (window.heap && userId) {
    window.heap.identify(userId);
    window.heap.addUserProperties({ email });
  } else if (window.heap && userUuid) {
    window.heap.identify(userUuid);
    window.heap.addUserProperties({ email });
  }
};

/**
 * We only reset the identity when a user logs out
 * Assumes that it's the same user using the device
 */
export const heapIdentityReset = () => {
  if (window.heap) {
    window.heap.resetIdentity();
  }
};

/**
 * Check if the current user is the current heap user
 * returns true if same user or no heap user on the account
 */
export const isSameHeapUser = ({ userId, userUuid }: { userId?: number; userUuid?: string }) => {
  const heapUserId = window?.heap?.userId;
  if (window.heap && userId) {
    // If heapUserId is empty, then it's a new user. return true
    return !heapUserId || Number(heapUserId) === userId;
  }
  if (window.heap && userUuid) {
    return !heapUserId || heapUserId === userUuid;
  }
  return false;
};

export function heapAddUserProperties(property: { [key: string]: any }) {
  if (window.heap) {
    window.heap.addUserProperties(property);
  }
}

export function heapAddEventProperties(property: { [key: string]: any }) {
  if (window.heap) {
    window.heap.addEventProperties(property);
  }
}

export function heapTrackProperties(
  type: string,
  property: { [key: string]: string | number | undefined },
) {
  if (window.heap) {
    window.heap.track(type, property);
  }
}

export function heapClearEventProperties() {
  if (window.heap) {
    window.heap.clearEventProperties();
  }
}
