import React from 'react';

import styles from './PageOverlay.module.css';

type Props = {
  children: React.ReactNode;
};

function Heading({ children }: Props) {
  return <h2 className={styles.heading}>{children}</h2>;
}

export default Heading;
