import Key from '@m/assets/svg/icon-valet-key';
import { KeycloakService } from '@m/keycloak-service/KeycloakService';
import { CSSProp } from 'styled-components';

import { LoginButton } from './LoginKeycloak.styled';

export default function KeycloakLoginButton({
  buttonText,
  buttonCss,
}: {
  buttonText?: string;
  buttonCss?: string | CSSProp;
}) {
  const doLogin = () => {
    KeycloakService.doLogin();
  };

  return (
    <LoginButton type="button" css={buttonCss} onClick={doLogin}>
      {buttonText || (
        <>
          <Key />
          <span css="margin-left: 4px;">180 partner sign-in</span>
        </>
      )}
    </LoginButton>
  );
}
