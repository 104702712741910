type Spaces = [
  /* 0px */
  number,

  /* 2px */
  number,

  /* 4px */
  number,

  /* 6px */
  number,

  /* 8px */
  number,

  /* 10px */
  number,

  /* 12px */
  number,

  /* 14px */
  number,

  /* 16px */
  number,

  /* 18px */
  number,

  /* 20px */
  number,

  /* 22px */
  number,

  /* 24px */
  number,

  /* 28px */
  number,

  /* 30px */
  number,

  /* 32px */
  number,

  /* 36px */
  number,

  /* 40px */
  number,

  /* 48px */
  number,

  /* 52px */
  number,

  /* 56px */
  number,

  /* 60px */
  number,

  /* 64px */
  number,
];

const space: Spaces = [
  0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 30, 32, 36, 40, 48, 52, 56, 60, 64,
];

export default space;
