import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
} from 'styled-system';

import { ThemeColorKeys } from '../scales/colors';
import { Fonts, FontSizes, LetterSpacings } from '../scales/typography';

import fontAlias from './fontSystemAlias';

import type { FontProps } from './fontSystemAlias';
import type { Globals } from 'csstype';
import type {
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
} from 'styled-system';

export type StyledSystemColorValue = ThemeColorKeys | Globals | 'currentcolor' | (string & {});

export type StyledSystemProps = SpaceProps &
  Omit<ColorProps, 'color'> &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  FontProps & {
    color?: StyledSystemColorValue;
    backgroundColor?: StyledSystemColorValue;
    borderColor?: StyledSystemColorValue;
    borderTopColor?: StyledSystemColorValue;
    borderRightColor?: StyledSystemColorValue;
    borderBottomColor?: StyledSystemColorValue;
    borderLeftColor?: StyledSystemColorValue;
    fontSize?: string | number | FontSizes | null;
    font?: Fonts | null;
    letterSpacing?: string | number | LetterSpacings | null;
  };

const withStyledSystem = compose(
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  fontAlias,
);

export default withStyledSystem;
