/**
 * Utility to run fetch() requests on a worker thread.
 *
 * Although the fetch() api is asynchronous, it is
 * still thread blocking when run on the main thread.
 * If there is a high volume of requests (e.g. when
 * fetching tramsactions), this can block execution on
 * the main thread and cause jank and lag.
 *
 * `workerize` is a module that allows easy creation
 * of workers without having to deal with messaging
 * between the main thread and worker thread.
 * https://github.com/developit/workerize
 */

// @ts-ignore
import workerize from 'workerize';

export interface WorkerizeFetchOptions extends RequestInit {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
  headers: Record<string, string>;
}

const worker = workerize(`
  export function wFetch(url, options) {
    return fetch(url, options);
  }
`);

export const fetchWorker = async (url: string, options: WorkerizeFetchOptions) => {
  const json = await worker.wFetch(url, options);
  return json;
};
