import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconValetKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="14"
    fill="none"
    viewBox="0 0 26 14"
    {...props}
  >
    <path
      fill="#fff"
      d="M23.667 4.667h-9.742A6.99 6.99 0 0 0 7.333 0c-3.861 0-7 3.138-7 7s3.139 7 7 7a6.99 6.99 0 0 0 6.592-4.667h.408l2.334 2.334L19 9.333l2.333 2.334L26 6.953zM7.333 10.5a3.51 3.51 0 0 1-3.5-3.5 3.51 3.51 0 0 1 3.5-3.5 3.51 3.51 0 0 1 3.5 3.5 3.51 3.51 0 0 1-3.5 3.5"
    />
  </svg>
);
export default SvgIconValetKey;
