import { hideAlert, hideBottomAlert, showAlert, showBottomAlert } from '@m/state/alert/actions';
import { createStore } from 'effector';

import type { AlertType } from '@m/state/alert/actions';

type State = {
  alertVisible: boolean;
  label: string | JSX.Element | null;
  type?: AlertType;
  duration?: number;
  css?: string | null;
  onClick?: (() => void) | null;
  hideCallback?: () => void;
  bottomAlert: {
    visible: boolean;
    label?: string | JSX.Element | null;
    type?: AlertType;
    customAlert?: JSX.Element | null;
    position?: number | null;
  };
};
const initialState: State = {
  alertVisible: false,
  label: null,
  type: null,
  duration: 6000,
  css: null,
  onClick: null,
  bottomAlert: {
    visible: false,
    label: null,
    type: null,
    customAlert: null,
    position: null,
  },
};

const store = createStore(initialState);

store.on(showAlert, (state, { label, type, duration, css, onClick }) => ({
  ...state,
  alertVisible: true,
  label,
  type,
  duration,
  css,
  onClick,
}));

store.on(hideAlert, (state) => ({ ...state, alertVisible: false, onClick: null }));

store.on(showBottomAlert, (state, { label, type, customAlert, position }) => ({
  ...state,
  bottomAlert: {
    visible: true,
    label,
    type,
    customAlert,
    position,
  },
}));

store.on(hideBottomAlert, (state) => ({
  ...state,
  bottomAlert: {
    ...state.bottomAlert,
    visible: false,
  },
}));

export default store;
