import { createContext } from 'react';

let lockRequestCount = 0;

function requestLock() {
  lockRequestCount += 1;
  const html = document.documentElement;
  if (lockRequestCount > 0 && html.style.overflow !== 'hidden') {
    html.style.overflow = 'hidden';
  }
}

function releaseLock() {
  lockRequestCount -= 1;
  const html = document.documentElement;
  if (lockRequestCount === 0) {
    // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    html.style.overflow = null;
  }
}
// @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
const ScrollLockContext = createContext();

function withScrollLock(WrappedComponent: any) {
  return (props: any) => (
    <ScrollLockContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        request: requestLock,
        release: releaseLock,
      }}
    >
      <ScrollLockContext.Consumer>
        {(scrollLock) => <WrappedComponent scrollLock={scrollLock} {...props} />}
      </ScrollLockContext.Consumer>
    </ScrollLockContext.Provider>
  );
}

export default withScrollLock;
