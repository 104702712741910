import React, { CSSProperties, useEffect } from 'react';

import cn from 'classnames';

import styles from './PageOverlay.module.css';

type StepProps = {
  [key: string]: any;
  active?: boolean;
  name: string;
  width: string | number;
  height: string | number;
  className?: string;
  style?: CSSProperties;
  updateContentSize?: (size: { width: string | number; height: string | number }) => void;
  children?: React.ReactNode;
};

function Step({
  active = false,
  name,
  width,
  height,
  className,
  style,
  updateContentSize = () => {},
  children,
  ...passProps
}: StepProps) {
  useEffect(() => {
    function updateSize() {
      if (active) {
        updateContentSize && updateContentSize({ width, height });
      }
    }

    updateSize();
  }, [active, width, height, updateContentSize]);

  const contentStyle = {
    width,
    height,
    ...style,
  };

  return (
    <div
      className={cn(styles.step, className, {
        [styles.active]: active,
      })}
      style={contentStyle}
      {...passProps}
    >
      {children}
    </div>
  );
}
Step.displayName = 'Step';

export default Step;
