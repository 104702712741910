import * as React from 'react';
import type { SVGProps } from 'react';

const SvgAlertCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12.007 16c.825 0 1.493.672 1.493 1.5 0 .77-.576 1.403-1.318 1.49l-.19.01a1.496 1.496 0 0 1-1.492-1.5c0-.77.576-1.403 1.318-1.49zM12 5a1.42 1.42 0 0 1 1.417 1.498l-.362 6.504a1.057 1.057 0 0 1-2.11 0l-.362-6.504A1.42 1.42 0 0 1 12 5" />
    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2" />
  </svg>
);
export default SvgAlertCircle;
