import React, { useCallback, useMemo, useState } from 'react';
import type { CSSProperties } from 'react';

import cn from 'classnames';

import styles from './PageOverlay.module.css';

type MultiStepContentProps = {
  [key: string]: any;
  activeStepName: string | null;
  className?: string;
  style?: CSSProperties;
  children: (React.ReactElement<{
    name: string;
    active: boolean;
    updateContentSize: (size: { width: string; height: string }) => void;
  }> | null)[];
};

function MultiStepContent({
  activeStepName,
  className,
  style,
  children,
  ...passProps
}: MultiStepContentProps) {
  const [size, setSize] = useState<CSSProperties>({
    width: undefined,
    height: undefined,
  });

  const contentStyle = useMemo(
    () => ({
      ...size,
      ...style,
    }),
    [size, style],
  );

  const updateContentSize = useCallback(
    ({ width, height }: { width: string; height: string }) => {
      if (size.width !== width || size.height !== height) {
        setSize({ width, height });
      }
    },
    [size.height, size.width],
  );

  const steps = React.Children.map(children, (child) =>
    child !== null
      ? React.cloneElement(child, {
          active: child.props.name === activeStepName,
          updateContentSize,
        })
      : null,
  );

  return (
    <div
      className={cn(styles.content, styles.multiStep, className)}
      style={contentStyle}
      {...passProps}
    >
      {steps}
    </div>
  );
}
MultiStepContent.displayName = 'MultiStepContent';

export default MultiStepContent;
