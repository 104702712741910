export default function isLocalhost() {
  const { hostname } = window.location;

  return Boolean(
    hostname === 'localhost' ||
      hostname === '[::1]' || // IPv6 localhost address.
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
      hostname.startsWith('192.168'), // local wifi network
  );
}
