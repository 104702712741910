/**
 * Formats US phone number without country code
 * ex. 123-456-7890
 * @param phoneNumber - string
 */
export function formatPhoneInputV2(phoneNumber?: string | null) {
  if (!phoneNumber) {
    return '';
  }
  const digits = phoneNumber.replace('+', '');
  if (digits.length <= 6) {
    const last4Digits = digits.substring(3);
    if (last4Digits.length > 0) {
      return `${digits.substring(0, 3)}-${last4Digits}`;
    }
    return `${digits.substring(0, 3)}`;
  }
  return `${digits.substring(0, 3)}-${digits.substring(3, 6)}-${digits.substring(6, 10)}`;
}

/**
 * Formats US phone number with country code and parentheses
 * ex. (123) 456-7890
 * @param number - string
 */
export function formatPhoneInput(number?: string | null) {
  if (!number) {
    return '';
  }
  let digits = number.replace('+', '');
  let prefix = ' ';
  if (digits.indexOf('1') === 0) {
    prefix = '+1 ';
    digits = digits.slice(1);
  } else if (digits.indexOf('1') !== 0) {
    prefix = '+1 ';
  }
  if (digits.length < 3) {
    return `${prefix}${digits}`;
  }
  if (digits.length <= 6) {
    const last4Digits = digits.substring(3);
    if (last4Digits.length > 0) {
      return `${prefix}(${digits.substring(0, 3)}) ${last4Digits}`;
    }

    return `${prefix}(${digits.substring(0, 3)}`;
  }
  return `${prefix}(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`;
}
