import { AppName } from '@m/types';

import { setCurrentAppName } from './currentApp';
import { initDatadog } from './datadogService';
import { setTokenName } from './http';
import initSentry from './sentryService';

export default function initApp(appName: AppName) {
  setCurrentAppName(appName);
  setTokenName(appName);
  initDatadog(appName);
  initSentry(appName);
}
