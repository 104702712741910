import React, { CSSProperties } from 'react';

import cn from 'classnames';

import styles from './PageOverlay.module.css';

type ContentProps = {
  [key: string]: any;
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
};

function Content({ width, height, className, style, children, ...passProps }: ContentProps) {
  const contentStyle = {
    width,
    height,
    ...style,
  };

  return (
    <div className={cn(styles.content, className)} style={contentStyle} {...passProps}>
      {children}
    </div>
  );
}

Content.displayName = 'Content';

export default Content;
