export type ThemeColors = {
  /**
   * `colors.blue1` [`#F6F6FF`]
   */
  blue1: string;
  /**
   * `colors.blue2` [`#e2dfff`]
   */
  blue2: string;
  /**
   * `colors.blue3` [`#AFACFF`]
   */
  blue3: string;
  /**
   * `colors.blue4` [`#9692FC`]
   */
  blue4: string;
  /**
   * `colors.blue5` [`#8480FF`]
   */
  blue5: string;
  /**
   * `colors.blue6` [`#5F59FF`]
   * Metropolis "blurple"
   */
  blue6: string;
  /**
   * `colors.blue7` [`#2F2D78`]
   */
  blue7: string;

  /**
   * `colors.orange 1[#FFF6F6`]
   */
  orange1: string;
  /**
   * `colors.orange2` [`#FFEFEF`]
   */
  orange2: string;
  /**
   * `colors.orange3` [`#C83F34`]
   */
  orange3: string;
  /**
   * `colors.orange4` [`#FF5D52`]
   */
  orange4: string;
  /**
   * `colors.orange5` [`#A62918`]
   */
  orange5: string;

  /**
   * `colors.green1` [`#CEF0DE`]
   */
  green1: string;
  /**
   * `colors.green2` [`#0FAA80`]
   */
  green2: string;
  /**
   * `colors.green3` [`#0F8F4D`]
   */
  green3: string;
  /**
   * `colors.green4` [`#004521`]
   */
  green4: string;

  /**
   * `colors.pink1` [`#FCEEFC`]
   */
  pink1: string;
  /**
   * `colors.pink2` [`#FF9CE0`]
   */
  pink2: string;
  /**
   * `colors.pink3` [`#4D0F3A`]
   */
  pink3: string;

  /**
   * `colors.yellow1` [`#FAEFCB`]
   */
  yellow1: string;
  /**
   * `colors.yellow2` [`#FAD148`]
   */
  yellow2: string;

  /**
   * `colors.grey1` [`#FFFFFF`]
   */
  grey1: string;
  /**
   * `colors.grey2` [`#EBEBEB`]
   */
  grey2: string;
  /**
   * `colors.grey3` [`#A4A4AD`]
   */
  grey3: string;
  /**
   * `colors.grey4` [`#949494`]
   */
  grey4: string;
  /**
   * `colors.grey5` [`#666666`]
   */
  grey5: string;
  /**
   * `colors.grey6` [`#323232`]
   */
  grey6: string;
  /**
   * `colors.grey7` [`#191919`]
   */
  grey7: string;

  /**
   * Metropolis "blurple"
   * `colors.brand` [`#5F59FF]
   * Alias of ``colors.blue6`
   * */
  brand: string;
  /**
   * `colors.accent` [`#FF5D52]
   * Alias of `colors.orange3`
   */
  accent: string;
  /**
   * `colors.coral` [`#FF5D52`]
   * Alias of `colors.orange3`
   */
  coral: string;
  /**
   * Used for section headings on light background
   * `colors.heading` [`#2F2D78`]
   * Alias of `colors.blue7`
   */
  heading: string;
  /**
   * `colors.white` [`#FFFFFF`]
   * Alias of `colors.grey1`
   */
  white: string;
  /**
   * `colors.black` [`#191919`]
   * Alias of `colors.grey7`
   */
  black: string;
  /**
   * Used for error messages and alerts
   * `colors.error` [`#A62918`]
   * Alias of `colors.orange5`
   */
  error: string;
  /**
   * Warning color, not used much in current design
   * `colors.warn` [`#FAD148`]
   * Alias of `colors.yellow2`
   */
  warn: string;
  /**
   * Used for success messaging/labels/alerts
   * `colors.success` [`#0F8F4D`]
   * Alias of `colors.green3`
   */
  success: string;
};

export type ThemeColorKeys = keyof ThemeColors;

const blue = {
  blue1: '#F6F6FF',
  blue2: '#e2dfff',
  blue3: '#AFACFF',
  blue4: '#9692FC',
  blue5: '#8480FF',
  blue6: '#5F59FF',
  blue7: '#2F2D78',
};
const orange = {
  orange1: '#FFF6F6',
  orange2: '#FFEFEF',
  orange3: '#C83F34',
  orange4: '#FF5D52',
  orange5: '#A62918',
};
const green = {
  green1: '#CEF0DE',
  green2: '#0FAA80',
  green3: '#0F8F4D',
  green4: '#004521',
};
const pink = {
  pink1: '#FCEEFC',
  pink2: '#FF9CE0',
  pink3: '#4D0F3A',
};
const yellow = {
  yellow1: '#FAEFCB',
  yellow2: '#FAD148',
};
const grey = {
  grey1: '#FFFFFF',
  grey2: '#EBEBEB',
  grey3: '#A4A4AD',
  grey4: '#949494',
  grey5: '#666666',
  grey6: '#323232',
  grey7: '#191919',
};

const aliases = {
  brand: blue.blue6,
  accent: orange.orange4,
  coral: orange.orange4,
  heading: blue.blue7,
  white: grey.grey1,
  black: grey.grey7,
  error: orange.orange5,
  warn: yellow.yellow2,
  success: green.green3,
};

const colors: ThemeColors = {
  ...blue,
  ...pink,
  ...orange,
  ...grey,
  ...yellow,
  ...green,
  ...aliases,
};

export default colors;
