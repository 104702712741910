import btnNormal from '@m/assets/svg/microsoft/ms_signin_light.svg';
import { normalizeButton } from '@m/styled/mixins';
import styled from 'styled-components';

export const MSALButton = styled.button`
  ${normalizeButton}

  display: flex;
  width: 215px;
  height: 41px;
  background-image: url(${btnNormal});
  background-size: 215px 41px;
  background-position: center;

  &:hover {
    color: #555555;
    background-image: linear-gradient(rgba(85, 85, 85, 0.1), rgba(85, 85, 85, 0.1)),
      url(${btnNormal});
  }

  &:active {
    background-image: linear-gradient(rgba(85, 85, 85, 0.2), rgba(85, 85, 85, 0.2)),
      url(${btnNormal});
  }

  &:disabled {
    background-image: linear-gradient(rgba(85, 85, 85, 0.2), rgba(85, 85, 85, 0.4)),
      url(${btnNormal});
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.coral};
`;
