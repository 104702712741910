export type LetterSpacings = {
  /** normal */
  auto: string;

  /** 0.02em */
  compact: string;

  /** 0.03em */
  narrow: string;

  normal: string;

  /** 0.05em */
  medium: string;

  /** 0.075em */
  expanded: string;

  /** 0.1em */
  wide: string;

  /** 0.13em */
  extraWide: string;

  /** 0.15em */
  superWide: string;
};

const letterSpacings: LetterSpacings = {
  auto: 'normal',
  compact: '0.02em',
  narrow: '0.03em',
  normal: '0.035em',
  medium: '0.05em',
  expanded: '0.075em',
  wide: '0.1em',
  extraWide: '0.13em',
  superWide: '0.15em',
};

export default letterSpacings;
