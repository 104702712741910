import type { HTMLAttributes } from 'react';

import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import { animated } from '@react-spring/web';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export type BoxProps = HTMLAttributes<HTMLDivElement> & StyledSystemProps;

export const Box = styled.div<StyledSystemProps>`
  ${withStyledSystem}
`;
Box.defaultProps = {
  position: 'relative',
  display: 'flex',
  flex: 1,
  lineHeight: 1,
};

export const AnimatedBox = animated(Box);
