import { createGlobalStyle } from 'styled-components';

import NeueMontrealBoldWoff2 from './NeueMontreal-Bold.woff2';
import NeueMontrealBoldItalicWoff2 from './NeueMontreal-BoldItalic.woff2';
import NeueMontrealItalicWoff2 from './NeueMontreal-Italic.woff2';
import NeueMontrealLightWoff2 from './NeueMontreal-Light.woff2';
import NeueMontrealLightItalicWoff2 from './NeueMontreal-LightItalic.woff2';
import NeueMontrealMediumWoff2 from './NeueMontreal-Medium.woff2';
import NeueMontrealMediumItalicWoff2 from './NeueMontreal-MediumItalic.woff2';
import NeueMontrealRegularWoff2 from './NeueMontreal-Regular.woff2';

const FontNeueMontreal = createGlobalStyle`
  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Bold'),
        local('Neue Montreal'),
        url(${NeueMontrealBoldWoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-Bold';
    src: local('NeueMontreal-Bold'),
        url(${NeueMontrealBoldWoff2}) format('woff2');
      font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-BoldItalic'),
        local('Neue Montreal'),
        url(${NeueMontrealBoldItalicWoff2}) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-BoldItalic';
    src: local('NeueMontreal-BoldItalic'),
        url(${NeueMontrealBoldItalicWoff2}) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Italic'),
        local('Neue Montreal'),
        url(${NeueMontrealItalicWoff2}) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-Italic';
    src: local('NeueMontreal-Italic'),
        url(${NeueMontrealItalicWoff2}) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Light'),
        local('Neue Montreal'),
        url(${NeueMontrealLightWoff2}) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-Light';
    src: local('NeueMontreal-Light'),
        url(${NeueMontrealLightWoff2}) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-LightItalic'),
        local('Neue Montreal'),
        url(${NeueMontrealLightItalicWoff2}) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-LightItalic';
    src: local('NeueMontreal-LightItalic'),
        url(${NeueMontrealLightItalicWoff2}) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Medium'),
        local('Neue Montreal'),
        url(${NeueMontrealMediumWoff2}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
        url(${NeueMontrealMediumWoff2}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-MediumItalic'),
        local('Neue Montreal'),
        url(${NeueMontrealMediumItalicWoff2}) format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-MediumItalic';
    src: local('NeueMontreal-MediumItalic'),
        url(${NeueMontrealMediumItalicWoff2}) format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Regular'),
        local('Neue Montreal'),
        url(${NeueMontrealRegularWoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NeueMontreal-Regular';
    src: local('NeueMontreal-Regular'),
        url(${NeueMontrealRegularWoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
`;

export default FontNeueMontreal;
