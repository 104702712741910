import colors from './scales/colors';
import space from './scales/space';
import typography from './scales/typography';

// TODO: Add alternate themes for specialist/admin

/** Customer-facing theme as used by web+native app */
const defaultTheme = {
  colors,
  ...typography,
  space,
  breakpoints: [],
};

export type ThemeType = typeof defaultTheme;
export default defaultTheme;

export * from './scales/colors';
export * from './scales/typography';
