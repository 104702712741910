import { apiFetch } from '@m/utils/http';

export const GateService = {
  listGatesAtSite: ({ siteId }: { siteId: number }) =>
    apiFetch(`/specialist/site/${siteId}/parkonect-gates`),

  openGate: ({
    siteId,
    laneId,
    visitId,
  }: {
    siteId: number;
    laneId: number;
    visitId?: number | null;
  }) =>
    apiFetch(
      `/specialist/site/${siteId}/lane/${laneId}/open-gate${visitId ? `?visitId=${visitId}` : ''}`,
      { method: 'POST' },
    ),

  listDoorsAtSite: ({ siteId }: { siteId: number }) => apiFetch(`/admin/site/${siteId}/doors`),

  openDoor: ({ siteId, doorId }: { siteId: number; doorId: number }) =>
    apiFetch(`/admin/site/${siteId}/doors/${doorId}/open`, { method: 'POST' }),
};
