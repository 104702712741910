import { LoadingOutlined } from '@ant-design/icons';
import { brandColorCycleSpin } from '@m/styled/keyframes';
import { normalizeButton } from '@m/styled/mixins';
import styled from 'styled-components';

import btnActive from './img/btn--active.svg';
import btnDisabled from './img/btn--disabled.svg';
import btnFocus from './img/btn--focus.svg';
import btnNormal from './img/btn--normal.svg';

export const Box = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledGoogleSignInButton = styled.button`
  ${normalizeButton}

  display: flex;
  width: 193px;
  height: 48px;
  background-image: url(${btnNormal});
  background-size: 193px 48px;
  background-position: center;

  &:focus {
    background-image: url(${btnFocus});
  }
  &:active {
    background-image: url(${btnActive});
  }
  &:disabled {
    background-image: url(${btnDisabled});
  }
`;

export const SpinningLoadingIcon = styled(LoadingOutlined)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  flex-basis: 0;
  border-radius: 50%;
  animation: ${brandColorCycleSpin} 5s linear 0ms infinite;

  & svg {
    display: flex;
    width: 24px;
    height: 24px;
  }
`;
