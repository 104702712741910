import { createEvent } from 'effector';

export type AlertType = 'info' | 'success' | 'error' | 'warning' | null;

export type ShowAlertPayload = {
  label: string | JSX.Element;
  type?: AlertType;
  duration?: number;
  css?: string | null;
  onClick?: () => void;
};

export type ShowBottomAlertPayload = {
  label?: string | JSX.Element;
  type?: AlertType;
  customAlert?: JSX.Element | null;
  position?: number | null;
};

export const showAlert = createEvent<ShowAlertPayload>();
export const hideAlert = createEvent<void>();

export const showBottomAlert = createEvent<ShowBottomAlertPayload>();
export const hideBottomAlert = createEvent<void>();
