import development from './envs/development';
import local from './envs/local';
import production from './envs/production';
import shared from './envs/shared';
import staging from './envs/staging';

const CONFIG_ENV =
  (process.env.REACT_APP_CONFIG_USE_EXTERNAL_API as EnvironmentType) ||
  (process.env.REACT_APP_CONFIG_ENV as EnvironmentType) ||
  'local';

const environments = {
  local,
  development,
  staging,
  production,
};

export type EnvironmentType = 'local' | 'development' | 'staging' | 'production';

const Configurator = Object.freeze({
  CONFIG_ENV,
  ...shared,
  ...environments[CONFIG_ENV],
});
export default Configurator;
