import { getStorageKey } from './common';

/**
 * Default authentication method
 */
let defaultAuthMethod: 'token' | 'cookie' = 'cookie';

/**
 * @param authMethod - Authentication method to use as default
 */
export function setDefaultAuthMethod(authMethod: 'token' | 'cookie') {
  defaultAuthMethod = authMethod;
}

/**
 * @returns 'token' | 'cookie' - Default authentication method
 */
export function getDefaultAuthMethod() {
  return defaultAuthMethod;
}

/**
 * Value for 'Authorization' header.
 * This value usually has the format `Bearer ${tokenFromApi}`
 * There is no need to prepend 'Bearer ' when using.
 */
let authToken: string | null = null;

/**
 * @returns Promise<string>   - resolves with `'Bearer ...'` string or `null`
 *                            - rejects with error if storage access not allowed
 */
export async function getAuthToken() {
  if (authToken !== null) {
    return authToken;
  }
  try {
    authToken = localStorage.getItem(getStorageKey());
    return authToken;
  } catch (e) {
    try {
      authToken = sessionStorage.getItem(getStorageKey());
      return authToken;
    } catch (err) {
      return null;
    }
  }
}

/**
 * Set authorization token value.
 *
 * @param token              - "Bearer ..." token to use for `Authorization` header
 * @returns Promise<string>  - resolves with token string or null
 *                           - rejects with error if no storage access
 */
export async function setAuthToken(token: string) {
  try {
    authToken = token;
    localStorage.setItem(getStorageKey(), token);
    return authToken;
  } catch (e) {
    try {
      sessionStorage.setItem(getStorageKey(), token);
      return authToken;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
}

/**
 * Clear token value and empty local/async storage value
 * @returns Promise
 */
export async function clearAuthToken() {
  try {
    authToken = null;
    localStorage.removeItem(getStorageKey());
  } catch (e) {
    try {
      sessionStorage.removeItem(getStorageKey());
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
}
