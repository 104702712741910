// Permissions
export const VIEW_SITES = 'VIEW_SITES';
export const EDIT_SITES = 'EDIT_SITES';
export const VIEW_EVENTS = 'VIEW_EVENTS';
export const EDIT_EVENTS = 'EDIT_EVENTS';
export const VIEW_USERS = 'VIEW_USERS';
export const EDIT_USERS = 'EDIT_USERS';
export const VIEW_AWS_CONFIG = 'VIEW_AWS_CONFIG';
export const EDIT_AWS_CONFIG = 'EDIT_AWS_CONFIG';
export const EDIT_FEATURE_FLAGS = 'Edit Feature Flags';

export const VIEW_SITES_ID = 1;
export const EDIT_SITES_ID = 2;
export const VIEW_EVENTS_ID = 3;
export const EDIT_EVENTS_ID = 4;
export const VIEW_USERS_ID = 5;
export const EDIT_USERS_ID = 6;
export const VIEW_AWS_CONFIG_ID = 7;
export const EDIT_AWS_CONFIG_ID = 8;
export const VIEW_PAYMENT_PROFILE_ID = 9;
export const EDIT_PAYMENT_PROFILE_ID = 10;
export const SET_RATES_ID = 11;
export const EDIT_ENTERPRISE_SUBSCRIPTIONS_ID = 12;
export const INTAKE_VEHICLES_ID = 15;
export const EDIT_FEATURE_FLAGS_ID = 16;

// Permission Groups
export const PERMISSION_GROUP_ADMIN = 'PERMISSION_GROUP_ADMIN';
export const PERMISSION_GROUP_SPECIALIST = 'PERMISSION_GROUP_SPECIALIST';
export const PERMISSION_GROUP_READ_ONLY = 'PERMISSION_GROUP_READ_ONLY';
export const PERMISSION_GROUP_SUPER_ADMIN = 'PERMISSION_GROUP_SUPER_ADMIN';
export const PERMISSION_GROUP_INTAKE = 'PERMISSION_GROUP_INTAKE';

export const PERMISSION_GROUP_ADMIN_ID = 1;
export const PERMISSION_GROUP_SPECIALIST_ID = 2;
export const PERMISSION_GROUP_READ_ONLY_ID = 3;
export const PERMISSION_GROUP_SUPER_ADMIN_ID = 4;
export const PERMISSION_GROUP_INTAKE_ID = 5;

// Shared
export const OTHER = 'Other';
export const OTHER_ID = 0;

export const permissionIdToName = {
  VIEW_SITES_ID: VIEW_SITES,
  EDIT_SITES_ID: EDIT_SITES,
  VIEW_EVENTS_ID: VIEW_EVENTS,
  EDIT_EVENTS_ID: EDIT_EVENTS,
  VIEW_USERS_ID: VIEW_USERS,
  EDIT_USERS_ID: EDIT_USERS,
  EDIT_FEATURE_FLAGS_ID: EDIT_FEATURE_FLAGS,
  OTHER_ID: OTHER,
};
export const permissionNameToId = {
  VIEW_SITES: VIEW_SITES_ID,
  EDIT_SITES: EDIT_SITES_ID,
  VIEW_EVENTS: VIEW_EVENTS_ID,
  EDIT_EVENTS: EDIT_EVENTS_ID,
  VIEW_USERS: VIEW_USERS_ID,
  EDIT_USERS: EDIT_USERS_ID,
  EDIT_FEATURE_FLAGS: EDIT_FEATURE_FLAGS_ID,
  OTHER: OTHER_ID,
};
export const permissionValueToName = {
  'View Sites': VIEW_SITES,
  'Edit Sites': EDIT_SITES,
  'View Events': VIEW_EVENTS,
  'Edit Events': EDIT_EVENTS,
  'View Users': VIEW_USERS,
  'Edit Users': EDIT_USERS,
  'Edit Feature Flags': EDIT_FEATURE_FLAGS,
  Other: OTHER,
};

export const permissionGroupIdToName = {
  PERMISSION_GROUP_ADMIN_ID: PERMISSION_GROUP_ADMIN,
  PERMISSION_GROUP_SPECIALIST_ID: PERMISSION_GROUP_SPECIALIST,
  PERMISSION_GROUP_READ_ONLY_ID: PERMISSION_GROUP_READ_ONLY,
  PERMISSION_GROUP_SUPER_ADMIN_ID: PERMISSION_GROUP_SUPER_ADMIN,
  OTHER_ID: OTHER,
};
export const permissionGroupNameToId = {
  PERMISSION_GROUP_ADMIN: PERMISSION_GROUP_ADMIN_ID,
  PERMISSION_GROUP_SPECIALIST: PERMISSION_GROUP_SPECIALIST_ID,
  PERMISSION_GROUP_READ_ONLY: PERMISSION_GROUP_READ_ONLY_ID,
  PERMISSION_GROUP_SUPER_ADMIN: PERMISSION_GROUP_SUPER_ADMIN_ID,
  OTHER: OTHER_ID,
};
export const permissionGroupValueToName = {
  Admin: PERMISSION_GROUP_ADMIN,
  Specialist: PERMISSION_GROUP_SPECIALIST,
  'Read Only': PERMISSION_GROUP_READ_ONLY,
  'Super Admin': PERMISSION_GROUP_SUPER_ADMIN,
  Other: OTHER,
};
