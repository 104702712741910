/**
 * Prefix for local/async storage auth key
 */
export const TOKEN_KEY_PREFIX = '@metropolis-io/token';

/**
 * The currently set value of token storage key
 */
let tokenStorageKey = TOKEN_KEY_PREFIX;

/**
 * Set the environment specific token nam so that apps can
 * use seperate login tokens.
 * e.g `setTokenName('admin')`
 */
export function setTokenName(name: string) {
  tokenStorageKey = `${TOKEN_KEY_PREFIX}/${name}`;
}

/**
 * Get the environment specific token name if one has been set.
 * e.g if `setTokenName('admin')` was previously called
 * `getTokenName()` will return 'admin'
 */
export function getTokenName() {
  return tokenStorageKey.replace(TOKEN_KEY_PREFIX, '').replace(/^\//, '');
}

/**
 * Reset token storage key to default string
 */
export function resetTokenNameDefault() {
  tokenStorageKey = TOKEN_KEY_PREFIX;
}

/**
 * Get the configured property key name for use with
 * `localStorage` or `AsyncStorage`
 */
export function getStorageKey() {
  return tokenStorageKey;
}
