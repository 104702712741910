import { KEYCLOAK_ENABLED } from '@m/config/env';
import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { KeycloakService } from '@m/keycloak-service/KeycloakService';
import GoogleAuth from '@m/utils/GoogleAuth';
import { heapClearEventProperties, heapIdentityReset } from '@m/utils/heap';
import { apiFetch, setAuthToken } from '@m/utils/http';
import { createEffect, createEvent } from 'effector';

import { userAnalytics } from 'utils/userAnalytics';

let gAuth: GoogleAuth;

export const getUser = createEffect({
  handler: () => apiFetch('/user'),
});

export const getUserById = createEffect((userId: number) => apiFetch(`/user/${userId}`));

export const setSelectedSiteId = createEvent<{ siteId: number }>();

export const showLoginPrompt = createEvent();

export const hideLoginPrompt = createEvent();

export const requestLoginCode = createEffect({
  handler: ({ phoneNumber }: { phoneNumber: string }) =>
    apiFetch('/specialist/login/send', {
      method: 'POST',
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ phoneNumber: string; }' is not assignable ...
      body: { phoneNumber },
    }),
});
export const requestIntakeLoginCode = createEffect({
  handler: ({ phoneNumber }: { phoneNumber: string }) =>
    apiFetch('/intake/login/send', {
      method: 'POST',
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ phoneNumber: string; }' is not assignable ...
      body: { phoneNumber },
    }),
});

export const verifyCode = createEffect({
  handler: async ({
    phoneNumber,
    code: secret,
    beginSession, // Stores session in cookie, else receives session token
  }: {
    phoneNumber?: string | null;
    code: string;
    beginSession: boolean;
  }) => {
    const sessionMode = beginSession ? 'cookie' : undefined;
    const response = await apiFetch('/user/verification', {
      params: {
        phoneNumber,
        secret,
        sessionMode: CONNECT_TO_EXTERNAL_API ? 'token' : sessionMode,
      },
    });
    CONNECT_TO_EXTERNAL_API && setAuthToken(response?.data?.token);

    return response;
  },
});

export const login = createEffect({
  handler: async () => {
    if (!gAuth) {
      gAuth = new GoogleAuth();
    }
    const { email, code, provider } = await gAuth.login();
    const response = await apiFetch('/login', {
      method: 'POST',
      body: {
        login: email,
        code,
        provider,
        sessionMode: CONNECT_TO_EXTERNAL_API ? 'token' : undefined,
      },
    });

    CONNECT_TO_EXTERNAL_API && setAuthToken(response?.data?.token);
    return response;
  },
});

export const loginMicrosoft = createEffect({
  handler: async ({ email, code }: { email: string; code: string }) => {
    const response = await apiFetch('/login', {
      method: 'POST',
      body: {
        login: email,
        code,
        provider: 'microsoft',
        sessionMode: CONNECT_TO_EXTERNAL_API ? 'token' : undefined,
      },
    });

    CONNECT_TO_EXTERNAL_API && setAuthToken(response?.data?.token);
    return response;
  },
});

export const logout = createEffect({
  handler: async () => {
    heapIdentityReset();
    heapClearEventProperties();

    if (KEYCLOAK_ENABLED && KeycloakService.isLoggedIn()) {
      await KeycloakService.doLogout();
      userAnalytics.unsetUser();
      return { success: true };
    }

    return apiFetch('/logout', { method: 'POST' }).finally(() => {
      userAnalytics.unsetUser();
    });
  },
});
