import { normalizeButton } from '@m/styled/mixins';
import styled from 'styled-components';

export const LoginButton = styled.button`
  ${normalizeButton}

  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  height: 41px;
  background-color: ${({ theme }) => theme.colors.grey4};

  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-rendering: geometricPrecision;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey5};
  }

  &:active {
    background-color: #555555;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey3};
  }
`;
