import type { AppName } from '@m/types';

let currentAppName: AppName;

export function setCurrentAppName(name: AppName) {
  currentAppName = name;
}

export function getCurrentAppName() {
  return currentAppName;
}
