import { datadogRum } from '@datadog/browser-rum';
import { ENABLE_RUM } from '@m/constants/FeatureFlags';
import { AppName } from '@m/types';

import type { User as DataDogUser } from '@datadog/browser-core';

import { User } from 'types/api';

const DD_APP_ID = process.env.REACT_APP_DD_APP_ID;
const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;

export function initDatadog(app: AppName) {
  if (ENABLE_RUM && DD_APP_ID && DD_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: DD_APP_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: `${app}-app`,
      env: process.env.REACT_APP_CONFIG_ENV,

      version: process.env.REACT_APP_VERSION || undefined,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}

export function dataDogSetUser(user: User) {
  if (ENABLE_RUM) {
    const { id: userId, ...userInfo } = user;
    datadogRum.setUser({
      id: userId.toString(),
      ...userInfo,
    } as DataDogUser);
  }
}

export function dataDogLogOut() {
  if (ENABLE_RUM) {
    datadogRum.clearUser();
  }
}
