import { TextInput } from '@m/components/uikit';
import styled from 'styled-components';

import type { TextInputProps, BoxProps } from '@m/components/uikit';

function StyledTextInput({
  className,
  ...props
}: {
  className?: string;
  containerProps?: BoxProps;
} & TextInputProps) {
  return <TextInput {...(props as TextInputProps)} containerProps={{ className }} />;
}

const Input = styled(StyledTextInput)<{ isEmpty?: boolean }>`
  & input {
    font-size: 18px;
    padding-bottom: 2px;
    letter-spacing: 0.05em;
    caret-color: ${({ theme }) => theme.colors.blue2};
    color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};

    &:focus {
      border-bottom: 1px solid ${({ theme }) => theme.colors.blue4};
    }

    & ~ label {
      transform: ${({ isEmpty }) =>
        isEmpty ? null : 'translate3d(0, 0, 0) scale3d(0.875, 0.875, 1) !important'};
    }
  }

  & label {
    color: ${({ theme }) => theme.colors.blue4};

    & + div {
      color: ${({ theme }) => theme.colors.grey3};
    }
  }
`;

export default Input;
