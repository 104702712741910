import theme from '../theme';

type CSSVarsType = {
  [key: string]: string | number;
};

function getCSSVarMap() {
  const { colors } = theme;

  const cssVars: CSSVarsType = {};

  Object.entries(colors).forEach(([name, value]) => {
    cssVars[`--colors-${name}`] = value;
  });

  return cssVars;
}

export default getCSSVarMap;
